<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title class="form-modal-header">
          <v-btn color="white" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <span class="text-h5 white--text">Novo Registro Manejo</span>
          <v-spacer></v-spacer>
          <v-btn color="white" text @click="save">Salvar</v-btn>
        </v-card-title>
        <v-card-text class="mt-12">
          <v-container>
            <v-row>
              <v-col cols="12" class="col-farm">
                <v-toolbar elevation="0" style="background: none">
                  <v-toolbar-title class="font-weight-bold">
                    Selecionar Fazenda
                  </v-toolbar-title>
                </v-toolbar>

                <v-container class="custom-container">
                  <v-row>
                    <v-col cols="3">
                      <custom-label label="Proprietário" />
                      <simple-select
                        :items="producers"
                        v-model="payload.producer_id"
                        placeholder="Produtor"
                        itemText="user.name"
                        itemValue="producer_id"
                        height="auto"
                        @input="loadFarms($event)"
                      />
                    </v-col>

                    <v-col cols="3">
                      <custom-label label="Fazenda" />
                      <simple-select
                        :items="farms"
                        :disabled="!producerSelected"
                        v-model="payload.farm_id"
                        placeholder="Fazenda"
                        itemText="name"
                        height="auto"
                        itemValue="id"
                        @input="loadFarmDetails($event)"
                      />
                    </v-col>

                    <v-col cols="2">
                      <custom-label label="Cultura" />
                      <simple-select
                        :items="culturesManagement"
                        :disabled="!producerSelected"
                        v-model="cultureSelected"
                        placeholder="Cultura"
                        itemText="name"
                        height="auto"
                        itemValue="id"
                        returnObject
                      />
                    </v-col>

                    <v-col>
                      <custom-label label="Safra" />
                      <simple-text-field
                        mask="harvest"
                        :disabled="!producerSelected"
                        placeholder="Safra"
                        height="auto"
                        v-model="farmDetails.harvestYearCalculation"
                      />
                    </v-col>

                    <v-col
                      v-if="
                        farmDetails.farm_size !== undefined &&
                        farmDetails.farm_size !== null
                      "
                    >
                      <p>Tamanho</p>
                      <p>{{ farmDetails.farm_size + " ha" }}</p>
                    </v-col>

                    <v-col v-if="getAS()">
                      <p>AS Responsável</p>
                      <p>{{ getAS() }}</p>
                    </v-col>
                  </v-row>

                  <v-row> </v-row>
                </v-container>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" class="col-index" v-if="farmSelected">
                <v-toolbar elevation="0" style="background: none">
                  <v-toolbar-title class="font-weight-bold">
                    Ponto de Coleta
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <primary-button label="+ Adicionar" @callAction="addPoint" />
                </v-toolbar>

                <v-container v-if="points.length > 0">
                  <v-container
                    v-for="(point,index) in points"
                    :key="point.id"
                    class="custom-container my-2 pa-8"
                  >
                    <v-row>
                      <v-col>
                        <v-row align="center">
                          <v-col cols="4">
                            <custom-label
                              :label="
                                'Ponto de Coleta ' + (index + 1)
                              "
                            />
                          </v-col>
                          <v-col cols="4">
                            <custom-label class="mt-8" label="Localização" />
                              <div class="d-flex pt-3 file">
                                <span class="text-upload">
                                  <p>Upload</p>
                                </span>

                                <v-file-input
                                  v-model="point.file"
                                  class="map-file"
                                  dense
                                  outlined
                                  height="48px"
                                  placeholder="Escolha um arquivo GTM"
                                  accept=".gtm"
                                  prepend-icon=""
                                  :rules="fileRules"
                                />
                              </div>
                          </v-col>
                          <v-col>
                            <custom-label class="mt-8" label="Talhão" />
                            <v-row align="center">
                              <div class="d-flex pa-4 mb-4">
                                <simple-select
                                  :items="fields"
                                  v-model="points[index].field_id"
                                  placeholder="Selecione o talhão"
                                  itemText="name"
                                  itemValue="id"
                                  @input="attributeField($event)"
                                />
                                <v-btn
                                  class="ml-2"
                                  icon
                                  @click="removePoint(index)"
                                >
                                  <v-icon>mdi-trash-can-outline</v-icon>
                                </v-btn>
                              </div>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-container>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-redirect-dialog
      :message="'Registro foi criado anteriormente, deseja atualizar o registro já existente?'"
      :routeName="'management-record-details'"
      :routeParams="{farmId: payload.farm_id, harvest: farmDetails.harvestYearCalculation, cultureId: cultureSelected.id}"
      :dialog.sync="showConfirmRedirectDialog"
      @closeDialog="closeConfirmRedirectDialog"
    />
  </div>
</template>

<script>
// import ManagementRecords from "@/domain/records/management-records/management-records";
import Management from "@/domain/management/management";
import Farms from "@/domain/farms/farms";
import Contracts from "@/domain/contracts/contracts";
import Fields from "@/domain/fields/fields";
import Cultures from "@/domain/cultures/cultures";

import SimpleSelect from "@/components/inputs/SimpleSelect";
import SimpleTextField from "@/components/inputs/SimpleTextField";
import CustomLabel from "@/components/texts/CustomLabel";
import PrimaryButton from "@/components/buttons/PrimaryButton";
import ConfirmRedirectDialog from "@/components/dialogs/ConfirmRedirectDialog";

import { toBase64 } from "../../../../support/utils/fileToBase64"

export default {
  name: "ManagementRecordFarmAdd",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },

    producers: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    SimpleSelect,
    SimpleTextField,
    CustomLabel,
    PrimaryButton,
    ConfirmRedirectDialog
  },
  data: () => ({
    payload: {
      fields: [],
    },

    managementRecordService: null,
    farmService: null,
    contractService: null,
    fieldsService: null,
    cultureService: null,

    managementIndexes: [],
    payloadIndexes: [],
    cultureSelected: {},

    producerSelected: false,
    farmSelected: false,
    farms: [],
    farmDetails: {},
    fields: [],
    names: [],
    avaliableFields: [],
    culturesManagement: [],

    collectionPoint: {
      id: "",
      field_id: "",
    },
    points: [],

    grades: ["6.0", "6.5", "7.0", "7.5", "8.0", "8.5", "9.0", "9.5", "10.0"],
  
    showConfirmRedirectDialog: false,
    fileRules: [
        (v) => {
          const fileType = v ? v.name.slice(v.name.lastIndexOf(".")) : null;
          if (!v) {
            return "O campo é obrigatório";
          } else if (fileType !== ".gtm") {
            return "Somente arquivo gtm é permitido";
          } else {
            return true;
          }
        },
      ],
  }),

  methods: {
    async loadFarms(producerId) {
      const loader = this.$loading.show();
      this.farms = [];
      this.fields = [];
      this.managementIndexes = [];
      this.culturesManagement = [];
      this.payloadIndexes = [];
      this.farmDetails = {};
      this.payload.farm_id = null;

      await this.cultureService.listManagementCultures().then((result) => {
        result.data.map((item) => {
          this.culturesManagement.push(item);
        });
      });

      await this.farmService.listByProducer(producerId).then((result) => {
        result.data.map((item) => {
          this.farms.push(item);
        });
      });

      await this.contractService
        .enabledIndexesByManagementAndProducer(producerId)
        .then((result) => {
          result.data.map((item) => {
            this.managementIndexes.push(item);
            this.payloadIndexes[item.index.id] = null;
          });
        });

      this.producerSelected = true;
      loader.hide();
    },

    loadFarmDetails(farmId) {
      const loader = this.$loading.show();
      this.farmDetails = {};

      this.farmService.getFarmDetails(farmId).then((result) => {
        this.farmDetails = result.data;
      });

      this.fieldsService.listByFarm(farmId).then((result) => {
        result.data.map((item) => {
          if (item.is_active === true) {
            this.fields.push(item);
            this.avaliableFields.push(item);
          }
        });
      });

      this.farmSelected = true;
      loader.hide();
    },

    handleSelect(indexId, indexGrade) {
      this.payloadIndexes[indexId] = indexGrade;
    },

    close() {
      this.$emit("closeDialog");
    },

    closeConfirmRedirectDialog() {
      this.showConfirmRedirectDialog = false;
    },

    validate() {
      if (!this.producerSelected) {
        this.$toasted.show("É necessário selecionar o produtor.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }
      if (!this.farmSelected) {
        this.$toasted.show("É necessário selecionar a fazenda.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }
      if (!this.cultureSelected.name) {
        this.$toasted.show("É necessário selecionar uma cultura.", {
          type: "error",
          position: "top-right",
        });

        return false;
      }
      if (this.points.length < 1) {
        this.$toasted.show(
          "É necessário cadastar ao menos um ponto de coleta.",
          {
            type: "error",
            position: "top-right",
          }
        );

        return false;
      }
      if (this.points.length > 0) {
        let hasAttributions = true;
        let hasFiles = true;

        this.points.forEach(function (value) {
          if (!value.field_id) hasAttributions = false;
          if (!value.file) hasFiles= false;
        });
        if (!hasAttributions) {
          this.$toasted.show(
            "É necessário atribuir um talhão para todos os pontos de coleta.",
            {
              type: "error",
              position: "top-right",
            }
          );

          return false;
        }
        if(!hasFiles){
          this.$toasted.show(
            "É necessário atribuir um arquivo de localização para os pontos de coleta.",
            {
              type: "error",
              position: "top-right",
            }
          );

          return false;
        }
      }
      return true;
    },
    

    async save() {
      if (this.validate()) {
        let selectedFields = [];

        for ( let point of this.points) {
          try{
            const file = await toBase64(point.file)       
            const selectedPoint = {
                field_id:point.field_id,
                file:file,
              }
            
            selectedFields.push(selectedPoint)
          } catch (error){
            console.error(error)
            this.$toasted.show(
              "Houve um problema com o upload dos arquivos, tente novamente",
              {
                type: "error",
                position: "top-right",
              }
            );
            return;
          }
        }
        let managementIndexesByCulture = [];

        this.cultureSelected.records[0].phases.map(phase => {
          phase.indexes.map(index => {
            managementIndexesByCulture.push(index.id);
          });
        });

        this.payload.fields = selectedFields;
        this.payload.names = this.names;
        this.payload.is_active = true;

        const loader = this.$loading.show();

        this.payload.harvest = this.farmDetails.harvestYearCalculation;

        let payloadIndexesFilter = {};

        this.payloadIndexes.filter((value, key) => {
          if (managementIndexesByCulture.includes(key)) {
            payloadIndexesFilter[key] = value;
          }
        });

        this.payload.indexes    = payloadIndexesFilter;
        this.payload.culture_id = this.cultureSelected.id;

        this.managementRecordService
          .create(this.payload)
          .then(() => {
            loader.hide();
            this.close();

            this.$toasted.show("Registro de manejo cadastrado com sucesso.", {
              type: "success",
            });
          })
          .catch(({response}) => {
            loader.hide();

            if (response.status === 409) {
              this.showConfirmRedirectDialog = true;
            }
            const { error } = response.data;
            this.$toasted.show(error, {
              type: "error",
            });
            
          });
      }
    },

    getAS() {
      const producer = this.farmDetails.producer;
      return producer ? producer.responsible_success_advisor.name : "";
    },

    addPoint() {
      if (this.fields.length < 1) {
        this.$toasted.show(
          "Não existem talhões cadastrados para a fazenda selecionada.",
          {
            type: "error",
            position: "bottom-right",
          }
        );
      } 
      else {

        this.collectionPoint = {
          field_id: "",
          file: undefined,
        };

        this.points.push(this.collectionPoint);
        this.names.push("Ponto de Coleta " + this.points.length);
      }
    },

    attributeField(event) {
      this.avaliableFields.splice(this.avaliableFields.indexOf(event), 1);
    },

    removePoint(point) {
      this.points.splice(point, 1);
      this.names.splice(point, 1);
    },
  },

  beforeMount() {
    this.payload = Management.newData();
    this.managementRecordService = Management;
    this.farmService = Farms;
    this.contractService = Contracts;
    this.fieldsService = Fields;
    this.cultureService = Cultures;
  },
};
</script>

<style scoped>
::v-deep .col-farm .v-toolbar__content {
  padding: 0;
}

.text-upload {
  display: flex;
  top: 48px;
  width: 87px;
  height: 48px;
  padding: 0.7rem 1.2rem;
  background: #e9ecef 0% 0% no-repeat padding-box;
  border: 1px solid #ced4da;
  border-radius: 1rem 0px 0px 1rem;
}
.text-upload > p {
  text-align: left;
  letter-spacing: 0.11px;
  color: #575757;
}

::v-deep .map-file .v-input__slot > fieldset{
    border-top-left-radius: 0px !important;  
    border-bottom-left-radius: 0px !important;
    cursor: pointer;
}
::v-deep .map-file .v-text-field__slot .v-file-input__text{
    cursor: pointer !important;
}

.map-file{
  cursor: pointer !important;
}


.custom-container {
  border: 1px solid #e9d8a6;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}
</style>
