<template>
  <div>
    <v-toolbar class="mb-8" elevation="0" style="background: none">
      <v-toolbar-title class="font-weight-bold">
        Registros de Manejo
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <primary-button
        v-if="authoritySlug === 'super-admin' || permissions.access_records"
        label="+ Adicionar"
        @callAction="addManagementRecordFarm"
      />
    </v-toolbar>

    <search-bar
      v-model="search"
      @externalFiltersClear="clearFilters"
      :noSecondaryInput="false"
      :noTertiaryInput="false"
      :noFourthInput="false"
      smallFields
    >
      <simple-select
        :items="
          managementRecords.map((item) => {
            return item.harvest;
          })
        "
        v-model="harvestSearch"
        class="mr-2"
        placeholder="Safra"
        @input="filterByHarvest"
        height="0"
        returnObject
      />
      <template slot="tertiary-input">
        <simple-select
          :items="
            managementRecords.map((item) => {
              return item;
            })
          "
          v-model="cultureSearch"
          class="mr-2"
          placeholder="Cultura"
          itemText="culture"
          itemValue="culture_id"
          @input="filterByCulture"
          height="0"
          returnObject
        />
      </template>
      <template slot="fourth-input">
        <simple-select
          :items="managementRecords.map((item) => {
            const status= item.status ? `Concluído` : `Em Progresso`;
            return status;
          })"
          v-model="selectedFarm"
          class="mr-4"
          placeholder="Status"
          itemText="name"
          itemValue="id"
          returnObject
          height="auto"
          @input="getFarmDetails"
        />
      </template>
    </search-bar>

    <v-data-table
      :footer-props="{
        'items-per-page-text': 'Linhas por página',
        'items-per-page-all-text': 'Todos',
      }"
      :headers="headers"
      :items="managementRecords"
      :search="search.text"
      no-data-text="Nenhum registro de manejo encontrado"
      class="px-12 elevation-1 yellow-border rounded-xl"
      sort-by="id"
      no-results-text="Nenhum registro correspondente encontrado"
    >
      <template v-slot:[`item.id`]="{ item }">
        {{ "#" + item.id }}
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip class="ma-2" :color="item.status ? 'green' : 'blue'" outlined pill>
          {{ item.status ? "Concluído" : "Em Progresso" }}
        </v-chip>
      </template>

      <template v-slot:[`item.created_at`]="{ item }">
        {{ item.created_at | dateTimezone }}
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon class="ml-4" small @click="viewItem(item)">
          mdi-information-outline
        </v-icon>

        <v-icon
          v-if="authoritySlug === 'super-admin' || permissions.access_records"
          class="ml-4"
          small
          @click="deleteItem(item)"
        >
          mdi-trash-can-outline
        </v-icon>
      </template>
    </v-data-table>

    <management-record-farm-add
      v-if="showManagementRecordFarmAdd"
      :dialog="showManagementRecordFarmAdd"
      :producers="producers"
      @closeDialog="reset()"
    />

    <confirm-destroy-dialog
      :title="'este registro'"
      :dialog.sync="showConfirmDestroyDialog"
      @closeDialog="closeConfirmDestroyDialog"
    />
  </div>
</template>

<script>
import LoginService from "@/domain/login/login.service";
import Management from "@/domain/management/management";
import Producers from "@/domain/producers/producers";
import Farms from "@/domain/farms/farms";

import ManagementRecordFarmAdd from "./ManagementRecordFarmAdd.vue";
import PrimaryButton from "@/components/buttons/PrimaryButton.vue";
import SearchBar from "@/components/layouts/SearchBar";
import SimpleSelect from "@/components/inputs/SimpleSelect";
import ConfirmDestroyDialog from "@/components/dialogs/ConfirmDestroyDialog";

import {getItem} from '@/services/local-storage.service';

export default {
  name: "ManagementRecordsTable",
  components: {
    ManagementRecordFarmAdd,
    PrimaryButton,
    SearchBar,
    SimpleSelect,
    ConfirmDestroyDialog
  },
  data() {
    return {
      authority: true,
      adminId: [1, 2],
      payload: {},

      managementRecordService: null,
      producerService: null,
      farmService: null,
      managementIndexService: null,

      managementRecords: [],
      producers: [],
      farmDetails: {},
      managementRecordSelected: {},

      dialog: false,
      showManagementRecordFarmAdd: false,
      showConfirmDestroyDialog: false,
      search: {},
      harvestSearch: "",
      cultureSearch: "",
      authoritySlug: "",
      permissions: ""
    }
  },

  computed: {
    headers() {
      return [
        {
          text: "ID",
          align: "start",
          value: "id",
          sortable: this.canBeSortable,
        },
        { text: "Fazenda", value: "farm_name", sortable: this.canBeSortable },
        { text: "Proprietário", value: "producer_name" },
        {
          text: "Safra",
          value: "harvest",
          sortable: this.canBeSortable,
          filter: (value) => {
            if (!this.harvestSearch) return true;
            return value === this.search.harvest;
          },
        },
        {
          text: "Cultura",
          value: "culture",
          filter: (value) => {
            if (!this.cultureSearch) return true
            return value === this.search.culture;
          }
        },
        { text: "Status", value: "status", sortable: this.canBeSortable },
        { text: "Detalhes", value: "actions", sortable: false },
      ];
    },

    isViewingAdmin() {
      return this.authority;
    },
  },

  methods: {
    getUserLogged() {
      const loader = this.$loading.show();

      LoginService.getUserLogged()
        .then((data) => {
          if (!this.adminId.includes(data.authority_id)) {
            this.authority = false;
          }

          loader.hide();
        })
        .catch(() => {
          location.reload();
          loader.hide();
        });
    },

    loadManagementRecords(params) {
      this.managementRecords = [];

      this.managementRecordService
        .list(params)
        .then((result) => {
          result.data.map((item) => {
            this.managementRecords.push(item);
          });
        })
        .catch(({ response }) => {
          const { error } = response.data;
          this.$toasted.show(error, {
            type: "error",
          });
        });
    },

    loadProducers() {
      const loader = this.$loading.show();
      this.producers = [];

      this.producerService.all().then((result) => {
        result.data.map((item) => {
          this.producers.push(item);
        });
      });

      loader.hide();
    },

    loadFarmDetails(farmId) {
      const loader = this.$loading.show();
      this.farmDetails = {};

      this.farmService.getFarmDetails(farmId).then((result) => {
        this.farmDetails = result.data;
      });

      loader.hide();
    },

    viewItem(item) {
      this.$router.push({
        name: "management-record-details",
        params: {
          farmId: item.farm_id,
          harvest: item.harvest,
          cultureId: item.culture_id,
          statusManagement: item.status,
        },
      });
    },

    deleteItem(item) {
      this.managementRecordSelected = {
        data : {
          'farm_id'    : item.farm_id,
          'harvest'    : item.harvest,
          'culture_id' : item.culture_id
        }
      };
      this.showConfirmDestroyDialog = true;
    },

    async destroy() {
      const loader = this.$loading.show();

      await this.managementRecordService
      .deleteCollectionPoint(this.managementRecordSelected)
      .then(() => {
        loader.hide();
        this.loadManagementRecords();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Registro de manejo removido com sucesso.", {
          type: "success",
        });
      })
      .catch(() => {
        loader.hide();
        this.showConfirmDestroyDialog = false;
        this.$toasted.show("Registro de manejo não foi removido.", {
          type: 'error'
        });
      });
    },

    closeConfirmDestroyDialog() {
      this.showConfirmDestroyDialog = false;
    },

    addManagementRecordFarm() {
      this.showManagementRecordFarmAdd = true;
      this.loadProducers();
    },

    reset() {
      this.dialog = false;
      this.showManagementRecordFarmAdd = false;
      this.loadManagementRecords();
    },

    filterByHarvest() {
      this.search.harvest = this.harvestSearch;
    },

    filterByCulture() {
      this.search.culture = this.cultureSearch.culture;
    },

    clearFilters() {
      this.harvestSearch = null;
      this.cultureSearch = null;
    },
  },

  mounted() {
    this.loadManagementRecords();
  },

  beforeMount() {
    this.getUserLogged();
    this.payload = Management.newData();
    this.managementRecordService = Management;
    this.producerService        = Producers;
    this.farmService            = Farms;
    this.authoritySlug = getItem('authoritySlug');
    this.permissions   = JSON.parse(getItem('permissions'));
  },
};
</script>

<style scoped>
::v-deep .col-farm .v-toolbar__content {
  padding: 0;
}

.custom-container {
  border: 1px solid #e9d8a6;
  border-radius: 5px;
  padding: 1.5rem 2rem 0.75rem 2rem;
}

p {
  color: #000000;
}

::v-deep .custom-secondary-input {
  width: 140px !important;
}

::v-deep .custom-tertiary-input {
  width: 180px !important;
}
</style>
