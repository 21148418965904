<template>
  <div>
    <management-records-table class="crud-data-table"></management-records-table>
  </div>
</template>

<script>
import ManagementRecordsTable from "./ManagementRecordsTable";

export default {
  name: "ManagementRecords",
  components: {
    ManagementRecordsTable,
  },
};
</script>
