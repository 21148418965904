var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-toolbar',{staticClass:"mb-8",staticStyle:{"background":"none"},attrs:{"elevation":"0"}},[_c('v-toolbar-title',{staticClass:"font-weight-bold"},[_vm._v(" Registros de Manejo ")]),_c('v-spacer'),(_vm.authoritySlug === 'super-admin' || _vm.permissions.access_records)?_c('primary-button',{attrs:{"label":"+ Adicionar"},on:{"callAction":_vm.addManagementRecordFarm}}):_vm._e()],1),_c('search-bar',{attrs:{"noSecondaryInput":false,"noTertiaryInput":false,"noFourthInput":false,"smallFields":""},on:{"externalFiltersClear":_vm.clearFilters},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}},[_c('simple-select',{staticClass:"mr-2",attrs:{"items":_vm.managementRecords.map((item) => {
          return item.harvest;
        }),"placeholder":"Safra","height":"0","returnObject":""},on:{"input":_vm.filterByHarvest},model:{value:(_vm.harvestSearch),callback:function ($$v) {_vm.harvestSearch=$$v},expression:"harvestSearch"}}),_c('template',{slot:"tertiary-input"},[_c('simple-select',{staticClass:"mr-2",attrs:{"items":_vm.managementRecords.map((item) => {
            return item;
          }),"placeholder":"Cultura","itemText":"culture","itemValue":"culture_id","height":"0","returnObject":""},on:{"input":_vm.filterByCulture},model:{value:(_vm.cultureSearch),callback:function ($$v) {_vm.cultureSearch=$$v},expression:"cultureSearch"}})],1),_c('template',{slot:"fourth-input"},[_c('simple-select',{staticClass:"mr-4",attrs:{"items":_vm.managementRecords.map((item) => {
          const status= item.status ? `Concluído` : `Em Progresso`;
          return status;
        }),"placeholder":"Status","itemText":"name","itemValue":"id","returnObject":"","height":"auto"},on:{"input":_vm.getFarmDetails},model:{value:(_vm.selectedFarm),callback:function ($$v) {_vm.selectedFarm=$$v},expression:"selectedFarm"}})],1)],2),_c('v-data-table',{staticClass:"px-12 elevation-1 yellow-border rounded-xl",attrs:{"footer-props":{
      'items-per-page-text': 'Linhas por página',
      'items-per-page-all-text': 'Todos',
    },"headers":_vm.headers,"items":_vm.managementRecords,"search":_vm.search.text,"no-data-text":"Nenhum registro de manejo encontrado","sort-by":"id","no-results-text":"Nenhum registro correspondente encontrado"},scopedSlots:_vm._u([{key:`item.id`,fn:function({ item }){return [_vm._v(" "+_vm._s("#" + item.id)+" ")]}},{key:`item.status`,fn:function({ item }){return [_c('v-chip',{staticClass:"ma-2",attrs:{"color":item.status ? 'green' : 'blue',"outlined":"","pill":""}},[_vm._v(" "+_vm._s(item.status ? "Concluído" : "Em Progresso")+" ")])]}},{key:`item.created_at`,fn:function({ item }){return [_vm._v(" "+_vm._s(_vm._f("dateTimezone")(item.created_at))+" ")]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{staticClass:"ml-4",attrs:{"small":""},on:{"click":function($event){return _vm.viewItem(item)}}},[_vm._v(" mdi-information-outline ")]),(_vm.authoritySlug === 'super-admin' || _vm.permissions.access_records)?_c('v-icon',{staticClass:"ml-4",attrs:{"small":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_vm._v(" mdi-trash-can-outline ")]):_vm._e()]}}],null,true)}),(_vm.showManagementRecordFarmAdd)?_c('management-record-farm-add',{attrs:{"dialog":_vm.showManagementRecordFarmAdd,"producers":_vm.producers},on:{"closeDialog":function($event){return _vm.reset()}}}):_vm._e(),_c('confirm-destroy-dialog',{attrs:{"title":'este registro',"dialog":_vm.showConfirmDestroyDialog},on:{"update:dialog":function($event){_vm.showConfirmDestroyDialog=$event},"closeDialog":_vm.closeConfirmDestroyDialog}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }